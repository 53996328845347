import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import nl from 'vuetify/lib/locale/nl';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { nl },
      current: 'nl',
    },
    theme: {
    themes: {
      light: {
        primary: colors.teal,
      },
    },
  },
});
