import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/authenticate',
    name: 'AuthenticateView',
    component: () => import('../views/AuthenticateView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const axios = require("axios").default;
router.beforeEach(async (to, _, next) => {
  var data = JSON.stringify({
    "token": localStorage.getItem("token")
  });

  var config = {
    method: 'post',
    url: 'https://takenapi.opdewolk.nl/authenticate/check',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  axios(config)
    .then(function (response) {
      if (response.data.success == false && to.name != 'AuthenticateView') {
        next({ name: 'AuthenticateView' })
      } else {
        next()
      }
    })
    .catch(function (error) {
      console.log(error);
    });
})


export default router
